var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt popDaily"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main bottom"
  }, [_c('div', {
    staticClass: "btn-close",
    style: {
      background: `url(${require(`@images/${_vm.theme}/icon-close.png`)}) center/auto 100% no-repeat`
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('div', {
    staticClass: "popPrompt-con"
  }, [_c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Reward")))]), _c('div', {
    staticClass: "popDaily__sub"
  }, [_vm._v(" Accuure coins for logging into the gome daily"), _c('br'), _vm._v(" without skipping ")]), _vm.steps && _vm.steps.length > 0 ? [_c('transition-group', {
    staticClass: "popDaily-list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterBtnBottom
    }
  }, _vm._l(_vm.steps, function (item, index) {
    return _c('div', {
      key: `o${index}`,
      staticClass: "popDaily-item",
      attrs: {
        "data-index": index / 2
      }
    }, [_c('div', {
      staticClass: "popDaily-item-body",
      style: {
        background: item.today ? `url(${require(`@images/${_vm.theme}/daily-bg.png`)}) center/auto 100% no-repeat` : ''
      }
    }, [_c('div', {
      staticClass: "popDaily-item__head",
      class: item.name == 'BIG DAY' ? 'item__bigday' : ''
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "popDaily-item-main",
      class: [item.completed ? 'has_sign' : '']
    }, [_c('div', {
      staticClass: "popDaily-item__icon"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "popDaily-item__num"
    }, [_vm._v(_vm._s(_vm._f("nFormatter")(item.coins * 3)))]), _c('div', {
      staticClass: "popDaily-item__tag"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/icon-ok.png`),
        "alt": ""
      }
    })])])]), _vm.steps[index - 1] && _vm.steps[index - 1].today && _vm.steps[index - 1].completed ? _c('div', {
      staticClass: "popDaily-item-time"
    }, [_vm._v(_vm._s(_vm.lastTimeStr))]) : _vm._e()]);
  }), 0)] : _vm._e(), _c('div', {
    staticClass: "btns"
  }, [_vm.todayItem && JSON.stringify(_vm.todayItem) != '{}' ? _c('div', {
    staticClass: "btn-claim",
    on: {
      "click": _vm.sign_in
    }
  }, [_vm._v(_vm._s(_vm.$lang("Claim")))]) : _vm._e()])], 2)])]), _c('pop-sign', {
    attrs: {
      "show": _vm.popSignShow,
      "taskInfo": _vm.todayItem
    },
    on: {
      "update:show": function ($event) {
        _vm.popSignShow = $event;
      }
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };