import loadConfig from "@Web3WalletConfig/config";
import store from "@store/index";
console.log("store", store);
class RocketAnimation {
  constructor(canvasId, config = {}) {
    this.canvas = document.getElementById(canvasId);
    this.ctx = this.canvas.getContext("2d");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    // 获取设备像素比
    const dpr = window.devicePixelRatio || 1;
    // 获取 canvas 的初始大小
    const rect = this.canvas.getBoundingClientRect();
    console.log("rect", rect, dpr);
    // 设置 canvas 的宽高为其 CSS 宽高乘以 DPR
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.ctx.scale(dpr, dpr);

    // 设置 canvas 的 CSS 宽高
    this.canvas.style.width = `${rect.width}px`;
    this.canvas.style.height = `${rect.height}px`;
    const sUserAgent = navigator.userAgent.toLowerCase();
    const isIos = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent);

    // 默认配置项
    const defaultConfig = {
      rocketSrc: require(`@images/${loadConfig.theme}/rocket.png`),
      starsSrc: require(`@images/${loadConfig.theme}/stars.jpg`),
      flamesSrc: [require(`@images/${loadConfig.theme}/flame0.png`), require(`@images/${loadConfig.theme}/flame1.png`), require(`@images/${loadConfig.theme}/flame2.png`), require(`@images/${loadConfig.theme}/flame3.png`)],
      rocketScale: isIos ? 1.3 * dpr : 1.1 * dpr, // 火箭放大倍数
      starsSpeed: 600, // 星空移动速度
      flameSize: { width: 200, height: 300 },
      totalDistance: 6500, // 星空总距离
      segments: 20, // 移动段数
    };

    console.log("this.isIos", this.isIos);

    // 合并默认配置和用户传递的配置
    this.config = { ...defaultConfig, ...config };
    console.log("this.config", this.config);

    this.rocketImg = new Image();
    this.starsImg = new Image();
    console.log("this.rocketImg", this.rocketImg, this.starsImg);
    this.flames = [];
    this.starsY = 0;
    this.rocketX = 0;
    this.rocketY = 0;
    this.flameIndex = 0;
    this.isClicking = false;
    this.timeoutId = null;
    this.upwardAnimationTriggered = false;
    this.initialStarsY = 0;
    this.upwardMovementSpeed = 3;
    this.isAnimating = false;
    this.animationTopFrameId = null;
    this.animationUpFrameId = null;
    this.animationDrawId = null;
    this.count = 0;
    this.ratio = 1 / dpr;
    // this.ratio = 0.285;
    this.resultList = this.kd(this.config.totalDistance);
    this.increment = this.config.starsSpeed;

    this.loadImages();
    // this.attachEvents();
  }


  kd(total) {
    const segments = this.config.segments; // 段数
    const results = [];
    let increment = 50; // 初始增量
    let cumulativeSum = 0; // 累计值

    for (let i = 0; i < segments - 1; i++) {
      cumulativeSum += increment;
      results.push(cumulativeSum);
      increment += 30; // 每段递增
    }
    const lastValue = total - cumulativeSum;
    results.push(cumulativeSum + lastValue);
    return results;
  }

  loadImages() {
    this.rocketImg.src = this.config.rocketSrc;
    this.starsImg.src = this.config.starsSrc;

    this.config.flamesSrc.forEach((src, index) => {
      this.flames[index] = new Image();
      this.flames[index].src = src;
    });
    this.rocketX = (this.canvas.width / 3) * this.ratio - 10;
    this.rocketY = (this.canvas.height - (this.config.flameSize.width * this.config.rocketScale * 2 - 50)) * this.ratio;

    this.starsImg.onload = (res) => {
      this.starsY = this.canvas.height - (this.starsImg.height * this.canvas.width) / this.starsImg.width;
      this.initialStarsY = this.starsY;
      this.draw();
    };
    this.rocketImg.onerror = (err) => {
      console.log("erererere", err);
    };
    this.starsImg.onerror = (err) => {
      console.log("erererere2222", err);
    };
  }

  drawImageScaled(img, x, y, maxWidth, maxHeight) {
    const imgAspect = img.width / img.height;
    let drawWidth = maxWidth;
    let drawHeight = maxHeight;

    if (drawWidth / drawHeight > imgAspect) {
      drawWidth = drawHeight * imgAspect;
    } else {
      drawHeight = drawWidth / imgAspect;
    }
    this.ctx.drawImage(img, x, y, drawWidth * this.ratio, drawHeight * this.ratio);
  }
  clearAnimFrame() {
    this.cancelAnimationFrame(this.animationDrawId);
    this.cancelAnimationFrame(this.animationTopFrameId);
    this.cancelAnimationFrame(this.animationUpFrameId);
  }
  draw() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawImageScaled(this.starsImg, 0, this.starsY * this.ratio, this.canvas.width, (this.starsImg.height * this.canvas.width) / this.starsImg.width);
    this.drawImageScaled(this.flames[this.flameIndex], this.rocketX, this.rocketY, this.config.flameSize.width * this.config.rocketScale, this.config.flameSize.height * this.config.rocketScale);
    this.flameIndex = (this.flameIndex + 1) % this.flames.length;
    this.drawImageScaled(this.rocketImg, this.rocketX, this.rocketY, this.config.flameSize.width * this.config.rocketScale, this.config.flameSize.height * this.config.rocketScale);

    this.animationDrawId = requestAnimationFrame(() => this.draw());
  }

  onMouseDown() {
    this.isClicking = true;
    this.upwardAnimationTriggered = false;
    clearTimeout(this.timeoutId);
    cancelAnimationFrame(this.animationTopFrameId);
    cancelAnimationFrame(this.animationUpFrameId);
    if (this.count < this.resultList.length - 1) {
      this.count++;
    } else {
      this.count = this.resultList.length - 1;
    }

    this.isAnimating = false;
    this.animateStars();
  }

  onMouseUp() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.isClicking = false;
      cancelAnimationFrame(this.animationTopFrameId);
      this.count--;
      if (this.count <= 0) {
        this.count = 0;
      }
      this.animateStars(true);
      if (!this.isClicking && !this.upwardAnimationTriggered && !this.isAnimating) {
        this.upwardAnimationTriggered = true;
        this.restoreStarsPosition();
      }
    }, 500);
  }

  animateStars(upward = false) {
    this.isClicking = !upward;
    if (this.starsY < 0) {
      const num = this.resultList[this.count] / this.increment;
      this.starsY += num;
    }

    if (this.starsY >= 0) {
      this.count = this.resultList.length - 1;
      cancelAnimationFrame(this.animationTopFrameId);
      cancelAnimationFrame(this.animationUpFrameId);
    }

    if (this.isClicking || upward) {
      this.animationTopFrameId = requestAnimationFrame(() => this.animateStars(upward));
    }
  }

  restoreStarsPosition() {
    if (!this.isAnimating) {
      cancelAnimationFrame(this.animationUpFrameId);
      this.isAnimating = true;
    }

    if (this.isClicking) {
      cancelAnimationFrame(this.animationUpFrameId);
      this.isAnimating = false;
      return;
    }

    if (this.starsY <= this.initialStarsY) {
      this.starsY = this.initialStarsY;
      this.upwardAnimationTriggered = false;
      this.isAnimating = false;
      cancelAnimationFrame(this.animationTopFrameId);
      cancelAnimationFrame(this.animationUpFrameId);
      this.count = 0;
      return;
    }
    this.starsY -= (this.resultList[this.count] / this.increment) * 4;
    this.animationUpFrameId = requestAnimationFrame(() => this.restoreStarsPosition());
  }
}

// 创建动画实例并传入配置项
// const rocketAnimation = new RocketAnimation("rocketCanvas", {
//   rocketScale: 3, // 自定义火箭大小
//   starsSpeed: 600, // 自定义星空速度
// });

export default RocketAnimation;
