import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popSign",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isFlag: true,
      loadingText: "...",
      selectIndex: 2
    };
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedDaily"]),
    ...userVuex.mapMutations(["setUserData"]),
    async onClaim() {
      if (this.selectIndex == 0) {
        this.onSubmit();
      } else {
        this.$loading.show();
        try {
          const AdController = await window.Adsgram.init({
            blockId: this.taskInfo.advid.toString()
          });
          await AdController.show().then(result => {
            this.$loading.hide();
            if (result.done && !this.taskInfo.completed) this.onSubmit();
          }).catch(result => {
            console.log(result);
            this.$loading.hide();
          });
          this.$loading.hide();
        } catch (e) {
          console.log(e);
          this.$loading.hide();
        }
        return;
      }
    },
    async onSubmit() {
      if (!this.isFlag) return;
      this.isFlag = false;
      let t = setInterval(() => {
        if (this.loadingText.length < 3) {
          this.loadingText += ".";
        } else {
          this.loadingText = ".";
        }
      }, 500);
      this.updatedDaily([1, this.selectIndex, rs => {
        if (rs.user) {
          let newUserData = {
            ...this.userData,
            ...rs.user
          };
          this.setUserData(newUserData);
        }
        this.$parent.popSignShow = false;
        this.isFlag = true;
      }, () => {
        this.isFlag = true;
      }]);
    },
    setSelectIndex(index) {
      this.selectIndex = index;
    }
  }
};