var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "reflectionIndex",
    class: [_vm.isMobile && 'wap', _vm.theme]
  }, [_c('city-head'), _c('div', {
    staticClass: "index-body page-body"
  }, [_c('div', {
    staticClass: "index-main page-main"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/index-bg-${_vm.userData.skin}.jpg`)}) center/100% auto no-repeat`
    }
  }), _c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top-main"
  }, [_c('div', {
    staticClass: "nav"
  }, _vm._l(_vm.navList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "nav-item wow zoomIn",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/nav-bg.png`)}) center/100% auto no-repeat`
      },
      attrs: {
        "data-wow-duration": "0.5s"
      },
      on: {
        "click": function ($event) {
          !_vm.isMobile && _vm.onSkip(item.path);
        },
        "touchstart": function ($event) {
          _vm.isMobile && _vm.onSkip(item.path);
        }
      }
    }, [_c('div', {
      staticClass: "nav-item-main"
    }, [_c('div', {
      staticClass: "nav-item__icon",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/${item.img}.png`)}) center/100% auto no-repeat`
      }
    }), _c('div', {
      staticClass: "nav-item__label"
    }, [_vm._v(_vm._s(item.label))]), _c('div', {
      staticClass: "nav-item__info"
    }, [_vm._v(_vm._s(item.info))])])]);
  }), 0)]), _c('div', {
    ref: "totalRef",
    staticClass: "total",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/new/coin.png`)})`
    }
  }), _c('div', {
    staticClass: "total__value",
    class: !_vm.isMobile && 'pc'
  }, [_vm.userData.coin ? [!_vm.isIos ? [_c('CountUp', {
    attrs: {
      "num": _vm.userData.coin
    }
  })] : [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))]] : _vm._e()], 2)])]), _c('div', {
    staticClass: "center"
  }, [_c('div', {
    staticClass: "coin",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleDownClick($event);
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.handleUpClick($event);
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.handleDownClick($event);
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.handleUpClick($event);
      }
    }
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "coin-main",
    class: [!_vm.isMobile && 'pc', _vm.isLight ? 'active' : ''],
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/new/avatar-${_vm.userData.avatar_url || 1}.png`)})`
    }
  })])]), _c('div', {
    ref: "coinListRef",
    staticClass: "coin_list"
  }), _c('div', {
    staticClass: "bottomX",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "progress wow fadeInLeft",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "progress__text"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-progress.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.userData.power)), _c('i', [_vm._v("/" + _vm._s(_vm.userData.power_max))])])])])]), _c('div', {
    staticClass: "btns wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "btn-boosts",
    on: {
      "click": function ($event) {
        !_vm.isMobile && _vm.onSkip('boost');
      },
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onSkip('boost');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/btn-icon-boost.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Boosts")) + " ")])])]), _c('m-menu'), [_c('div', {
    ref: "blockRef",
    staticClass: "block",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleDownClick($event);
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.handleUpClick($event);
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.handleDownClick($event);
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.handleUpClick($event);
      }
    }
  }, _vm._l(_vm.blockAnimList, function (item, index) {
    return _c('span', {
      key: index,
      class: item.bool && 'active',
      style: {
        left: item.x,
        top: item.y
      }
    }, [_vm._v("+" + _vm._s(_vm.turbo_temp_times <= 0 ? _vm.userData.multi_tap : _vm.userData.multi_tap * _vm.turbo_temp_times))]);
  }), 0)], _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTurboIcon,
      expression: "isShowTurboIcon"
    }],
    ref: "turboRef",
    staticClass: "turbo",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      },
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/turbo.png`),
      "alt": ""
    }
  })])], 2)]), _c('pop-robot', {
    attrs: {
      "show": _vm.popRobotShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRobotShow = $event;
      }
    }
  }), _c('pop-login-reward', {
    attrs: {
      "show": _vm.popLoginRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLoginRewardShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };