var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "upgold__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/pop-up-gold-icon-0.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "upgold__title"
  }, [_vm._v(_vm._s(_vm.$lang("Up to Gold")))]), _c('div', {
    staticClass: "upgold__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Cpmgrats!You're reached Gold Level!"))), _c('br'), _vm._v(" Enjoy "), _c('span', [_vm._v("100,000")]), _vm._v(" tapCoin award ")]), _c('div', {
    staticClass: "btn-claim"
  }, [_vm._v(_vm._s(_vm.$lang("Claim")))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };