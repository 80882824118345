var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "rank page",
    class: [_vm.isMobile && 'wap'],
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_vm.theme === 'rocket' ? _c('page-rank') : _vm.theme === 'city' ? _c('city-page-rank') : _c('div', {
    staticClass: "rank-main"
  }, [[_c('div', {
    staticClass: "switch"
  }, [_c('div', {
    staticClass: "switch-arrow left",
    class: _vm.isMinLevel && 'disable',
    on: {
      "click": function ($event) {
        return _vm.subLevel();
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _c('div', {
    staticClass: "switch-main"
  }, [_c('div', {
    staticClass: "switch-item"
  }, [_c('div', {
    staticClass: "img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/level/${_vm.getLevelName(_vm.level)}.png`)})`
    }
  }), _vm.selectTab == 0 ? [_c('div', {
    staticClass: "switch__name"
  }, [_vm._v(_vm._s(_vm._f("getLevelName")(_vm.level)))]), _c('div', {
    staticClass: "switch__value"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.userData.user_level >= _vm.level ? _vm.userData.coin : "0")) + " / " + _vm._s(_vm._f("nFormatter")(_vm.setting.userLevelCoin[_vm.level + 1] ? _vm.setting.userLevelCoin[_vm.level + 1] : _vm.setting.userLevelCoin[_vm.level])))])] : [_c('div', {
    staticClass: "switch__name"
  }, [_vm._v(_vm._s(_vm._f("getLevelName")(_vm.level)))]), _c('div', {
    staticClass: "switch__value"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.userData.user_group_level >= _vm.level ? _vm.userData.user_group_coin : "0")) + " / " + _vm._s(_vm._f("nFormatter")(_vm.setting.squadLevelCoin[_vm.level + 1] ? _vm.setting.squadLevelCoin[_vm.level + 1] : _vm.setting.squadLevelCoin[_vm.level])))])]], 2)]), _c('div', {
    staticClass: "switch-arrow right",
    class: _vm.isMaxLevel && 'disable',
    on: {
      "click": function ($event) {
        return _vm.plusLevel();
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]), _c('div', {
    staticClass: "mTab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mTab-item",
      class: item.value == _vm.selectTab && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _vm.list[_vm.selectTab] && _vm.list[_vm.selectTab][_vm.level] && _vm.list[_vm.selectTab][_vm.level].length > 0 ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list[_vm.selectTab][_vm.level], function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.onDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-icon"
    }, [index === 0 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-1.png`),
        "alt": ""
      }
    }) : index === 1 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-2.png`),
        "alt": ""
      }
    }) : index === 2 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-3.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(index + 1))])]), _vm.selectTab == 1 ? [_c('m-head', {
      attrs: {
        "src": item.group_icon
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.group_name))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.group_coin, 3, true)) + " ")])]), _c('div', {
      staticClass: "op"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1)] : [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.coin)) + " ")])]), item.id == _vm.userData.id ? _c('div', {
      staticClass: "op you"
    }, [_vm._v("You")]) : _vm._e()]], 2);
  }), 0) : _vm._e()]], 2), _c('pop-squad', {
    attrs: {
      "show": _vm.popSquadShow,
      "id": _vm.selectSquadId
    },
    on: {
      "update:show": function ($event) {
        _vm.popSquadShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };