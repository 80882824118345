const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
export default {
  name: "popDaily",
  props: {
    show: Boolean
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...dailyViex.mapState(["steps", "tomorrowDistance"])
  },
  data() {
    return {
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: "",
      popSignShow: false,
      todayItem: {}
    };
  },
  watch: {
    show: {
      async handler(bool) {
        this.$nextTick(() => {
          const body = document.querySelector("body");
          bool ? stop(body) : move(body);
          body.append ? body.append(this.$el) : body.appendChild(this.$el);
        });
        if (!bool) {
          this.todayItem = {};
          if (this.lastTimer) {
            clearInterval(this.lastTimer);
          }
        } else {
          await this.getSteps([1]);
          this.lastTime = this.tomorrowDistance;
          this.countDown();
          const todayItem = this.steps.filter(item => {
            return item.today && !item.completed;
          });
          if (todayItem.length > 0) this.todayItem = todayItem[0];
          console.log("todayItem", this.todayItem);
        }
      }
    }
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedDaily"]),
    sign_in() {
      console.log(333222);
      // if (!item.completed && item.today) {
      //   this.todayItem = item;
      // }
      this.popSignShow = true;
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
        console.log("lastTime", this.lastTime, this.lastTimeStr);
      }, 1000);
    }
  }
};