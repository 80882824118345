import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");

export const cityMixin = {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData", "extraInvite"]),
  },
  data() {
    const cityList = [
      {
        label: "Open Sesame",
        lock: false,
        path: "",
      },
      {
        label: "Premium Store",
        lock: true,
        path: "/store",
      },
      {
        label: "Store",
        lock: false,
        path: "",
      },
      {
        label: "Expedition",
        lock: false,
        path: "",
      },
      {
        label: "Lottery",
        lock: false,
        path: "",
      },
    ];
    return {
      cityList,
    };
  },
  mounted() {
    this.setWowAnim()
  },
  beforeRouteLeave(to, from, next) {},
  methods: {
    onSkip(item) {
      if (!item.lock) return;
      this.$router.push(item.path);
    },
  },
};
