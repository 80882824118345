import { mMenuMixin } from "@mixin/mMenu";
export default {
  name: "cityMmenu",
  mixins: [mMenuMixin],
  data() {
    const menuList = [{
      label: "Play",
      value: "/",
      img: "index-btn-play"
    }, {
      label: "City",
      value: "/city",
      img: "index-btn-city"
    }, {
      label: "lmprove",
      value: "/mine",
      img: "index-btn-improve"
    }, {
      label: "Friends",
      value: "/frens",
      img: "index-btn-frens"
    }, {
      label: "Tasks",
      value: "/earn",
      img: "index-btn-task"
    }];
    return {
      selectMenu: "/",
      menuList
    };
  }
};