var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_vm.row.opened == 0 ? _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/unknown.png`),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": _vm.row.lucky_task_icon,
      "alt": ""
    }
  })]), _vm.row.opened == 1 ? _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(_vm._s(_vm.row.lucky_task_name))]) : _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v("Hint")]), _c('div', {
    staticClass: "energy__threetitle"
  }, [_vm._v(" " + _vm._s(_vm.row.description) + " ")]), _c('div', {
    staticClass: "btn-level",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("I am lucky")) + " ")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };