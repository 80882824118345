var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityMmenu"
  }, [_c('div', {
    staticClass: "cityMmenu-main"
  }, [_c('div', {
    staticClass: "menu"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "menu-item",
      class: _vm.route === item.value && 'active',
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          !_vm.isMobile && _vm.onSkips(item.value);
        },
        "touchstart": function ($event) {
          _vm.isMobile && _vm.onSkips(item.value);
        }
      }
    }, [_c('div', {
      staticClass: "menu-icon",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/${_vm.route === item.value ? item.img + '-active' : item.img}.png`)}) center/auto 100% no-repeat`
      }
    }), _c('div', {
      staticClass: "menu-label"
    }, [_vm._v(_vm._s(item.label))]), item.value === _vm.route ? _c('div', {
      staticClass: "menu-bg",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/menu-bg.png`)}) center top/100% auto no-repeat`
      }
    }) : _vm._e()]);
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };