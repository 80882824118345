import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "city",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  mounted() {
    this.setWowAnim();
  }
};