var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "preload-image"
  }, [_c('img', {
    staticClass: "preload-bg",
    attrs: {
      "alt": "",
      "src": "/perload.jpg"
    }
  }), _vm.imgsSum ? _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "progress-text"
  }, [_vm._v(_vm._s(_vm.loadedCount ? parseInt(_vm.loadedCount / _vm.imgsSum * _vm.total) : "--") + "%")]), _c('div', {
    staticClass: "progress-bar",
    style: {
      width: `${parseInt(_vm.loadedCount / _vm.imgsSum * _vm.total)}%`
    }
  }, [_c('div', {
    staticClass: "bar"
  })])]), _c('div', {
    staticClass: "progress-tip",
    class: !_vm.tipType && 'active'
  }, [_vm._v(_vm._s(_vm.tip))])]) : _vm._e()]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };