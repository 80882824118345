var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "squad page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_vm.theme === 'rocket' ? [_c('page-join')] : _vm._e(), _vm.theme === 'city' ? [_c('city-page-join')] : [_c('div', {
    staticClass: "squad-main"
  }, [_c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "intro-img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/join-icon-frens.png`)})`
    }
  }), _c('div', {
    staticClass: "intro__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join Squad")))]), _c('div', {
    staticClass: "intro__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Yes! Join a squad to swlpe and eari together.")) + " "), _c('p', [_vm._v(_vm._s(_vm.$lang("Maximize profits and climb the rinkings!")))])])]), _c('div', {
    staticClass: "btn-join",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onJoin
    }
  }, [_vm._v(_vm._s(_vm.$lang("Creat or Join a custom squad")))]), _vm.squadList.length > 0 ? _c('div', {
    staticClass: "recommend"
  }, [_c('div', {
    staticClass: "recommend__title"
  }, [_vm._v(_vm._s(_vm.$lang("Recommend Squad")))]), _c('div', {
    staticClass: "recommend-list"
  }, _vm._l(_vm.squadList, function (item, index) {
    return _c('div', {
      staticClass: "recommend-item",
      on: {
        "click": function ($event) {
          return _vm.onRecommend(item);
        }
      }
    }, [_c('m-head', {
      attrs: {
        "src": item.group_icon
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.group_name))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.group_coin, 3, true)) + " ")])]), _c('div', {
      staticClass: "op"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1)], 1);
  }), 0)]) : _vm._e()])], _c('pop-join', {
    attrs: {
      "show": _vm.popJoinShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popJoinShow = $event;
      }
    }
  }), _c('pop-squad', {
    attrs: {
      "show": _vm.popSquadShow,
      "id": _vm.selectSquadId
    },
    on: {
      "update:show": function ($event) {
        _vm.popSquadShow = $event;
      }
    }
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };