import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const mineVuex = createNamespacedHelpers("mine");
export default {
  name: "mine",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...mineVuex.mapState(["categoryList", "earnings", "hourEearnings", "specialsMine", "taskList", "selectTab", "specialsMineInfo"])
  },
  data() {
    return {
      popEnergyShow: false,
      taskInfo: {},
      mineBalance: 0,
      timeoutCount: [],
      notEnoughCount: 1,
      refreshTime: 0,
      mineIntervalTime: 5 * 60 * 1000,
      popEngrayTipShow: false,
      //taskListArray:[],
      //taskList:{},
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: "",
      popAwardShow: false,
      popAwardTipShow: false,
      awardCoin: 0,
      popSpecialsDetailShow: false,
      specialsItem: {},
      mineTimer: null,
      openTimer: null,
      selectCategory: {},
      isLoading: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEnergyShow = false;
    this.popEngrayTipShow = false;
    this.popAwardShow = false;
    this.popAwardTipShow = false;
    this.popSpecialsDetailShow = false;
    this.sysCoin();
    if (this.lastTimer) {
      clearInterval(this.lastTimer);
    }
    this.timeoutCount.forEach(item => {
      clearTimeout(item);
    });
    clearInterval(this.mineTimer);
    clearTimeout(this.openTimer);
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    if (this.theme === 'rocket') return;
    if (this.theme === 'city') return;
    this.mineBalance = this.userData.coin;
    if (this.categoryList.length == 0) {
      await this.getCategoryList();
      if (this.categoryList.length > 0) {
        this.setSelectTab(this.categoryList[0].id);
        this.selectCategory = this.categoryList.find(item => {
          return item.id == this.categoryList[0].id;
        });
        if (!this.taskList[this.selectTab] || this.taskList[this.selectTab].length == 0) {
          this.isLoading = true;
          await this.getTaskList([this.selectTab, data => {
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          }]);
        }
      }
    } else {
      this.selectCategory = this.categoryList.find(item => {
        return item.id == this.selectTab;
      });
    }
    this.openTimer = setTimeout(async () => {
      await this.getEarningsIncrement();
      this.mineBalance = this.earnings.balance;
      let timeOut = setTimeout(this.updateMineBalance, 1000);
      this.timeoutCount.push(timeOut);
      this.mineTimer = setInterval(this.doMine, this.mineIntervalTime);
      let currentDate = new Date();
      this.refreshTime = Math.floor(currentDate.getTime() / 1000);
    }, 2000);
    this.doSpecialsMine();
  },
  watch: {
    earnings: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mineVuex.mapActions(["getCategoryList", "getTaskList", "getEarningsIncrement", "mine", "getSpecialsMine"]),
    ...mineVuex.mapMutations(["setSelectTab", "setSpecialsMineInfo", "setTaskList", "setEarnings"]),
    ...userVuex.mapMutations(["setUserData"]),
    onBoo(item) {
      if (item.upgradable) {
        this.taskInfo = item;
        this.popEnergyShow = true;
      }
    },
    async click_tab(type) {
      this.setSelectTab(type);
      this.selectCategory = this.categoryList.find(item => {
        return item.id == type;
      });
      this.$loading.show(this.selectCategory);
      document.getElementById("mine").scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth" // 可选，平滑滚动
      });

      if (!this.taskList[type] || this.taskList[type].length == 0) {
        await this.getTaskList([type, data => {
          // this.taskListArray[type + '_list'] = data
          // this.taskList = data
          this.$loading.hide();
        }, () => {
          this.$loading.hide();
        }]);
      } else {
        //this.taskList = this.taskListArray[type + '_list']
        this.$loading.hide();
      }
    },
    updateMineBalance() {
      this.timeoutCount.forEach(item => {
        clearTimeout(item);
      });
      this.timeoutCount = [];
      let tmp_earning = 0;
      let currentDate = new Date();
      let currentTime = Math.floor(currentDate.getTime() / 1000);
      let timeOut = 0;
      let t = 0;
      let rollCondition = 10;
      if (this.earnings.hour_earnings > 0) {
        if (this.mineBalance >= this.earnings.balance) {
          tmp_earning = this.earnings.hour_earnings / 3600 * (currentTime - this.refreshTime) + this.earnings.balance - this.mineBalance;
        } else {
          tmp_earning = this.earnings.balance - this.mineBalance;
        }
        if (tmp_earning > 0 && tmp_earning < 10) {
          rollCondition = Math.floor(tmp_earning);
        }
        //console.log(1,tmp_earning,this.mineBalance)
        while (tmp_earning > 0 && t < rollCondition) {
          let tmp_inc = tmp_earning / rollCondition;
          if (t == rollCondition - 1) {
            tmp_inc = tmp_earning - Math.floor(tmp_earning / rollCondition) * (rollCondition - 1);
          }
          timeOut = setTimeout(() => {
            this.mineBalance += Math.floor(tmp_inc);
            //console.log(2,tmp_inc,this.mineBalance)
          }, 30 * t);
          this.timeoutCount.push(timeOut);
          t++;
        }
        if (this.mineBalance < this.earnings.balance) {
          timeOut = setTimeout(() => {
            this.mineBalance = this.earnings.balance;
          }, 30 * t);
          this.timeoutCount.push(timeOut);
        } else {
          // timeOut = setTimeout(()=>{
          //   //this.mineBalance += Math.floor(tmp_earning);
          //   //console.log(3,this.mineBalance)
          // },30 * t);
          // this.timeoutCount.push(timeOut)
        }
      }
      timeOut = setTimeout(this.updateMineBalance, 1000 + 30 * t);
      this.timeoutCount.push(timeOut);
    },
    doMine() {
      //console.log('mine')
      let currentDate = new Date();
      this.refreshTime = Math.floor(currentDate.getTime() / 1000);
      this.mine();
    },
    async refreshData() {
      try {
        let currentDate = new Date();
        this.refreshTime = Math.floor(currentDate.getTime() / 1000);
        this.mineBalance = this.earnings.balance;
        this.$loading.show();
        //this.categoryList.forEach(async(item)=>{
        //    let tabIdx = item.id
        let tmpList = Object.assign({}, this.taskList);
        //console.log("tmpList",tmpList)
        for (let key in tmpList) {
          if (key != this.selectTab) {
            this.setTaskList({
              type: key,
              list: []
            });
          }
        }
        await this.getTaskList([this.selectTab, data => {
          //this.taskListArray[tabIdx + "_list"] = data
          //if(tabIdx == this.tabIndex)
          //{
          //  this.taskList = data
          this.$loading.hide();
          //}
        }, () => {
          this.$loading.hide();
        }]);
        //})
        setTimeout(() => {
          this.$loading.hide();
        }, 2000);
        this.timeoutCount.forEach(item => {
          clearTimeout(item);
        });
        this.sysCoin();
        let timeOut = setTimeout(this.updateMineBalance, 1000);
        this.timeoutCount.push(timeOut);
      } catch (e) {
        this.refreshData();
      }
    },
    sysCoin() {
      let newUserData = {
        coin: this.mineBalance
      };
      //console.log(newUserData)
      this.setUserData(newUserData);
      //console.log(this.userData)
    },

    showTip() {
      this.popEngrayTipShow = true;
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
      }, 1000);
    },
    onAwardTip() {
      this.popAwardTipShow = true;
    },
    async doSpecialsMine() {
      try {
        await this.getSpecialsMine();
        let specialsMineInfo = {
          coin: 0,
          totalCoin: 0,
          list: []
        };
        specialsMineInfo.list = [];
        specialsMineInfo.coin = 0;
        specialsMineInfo.totalCoin = 0;
        this.specialsMine.currents.forEach((item, index) => {
          if (index < 3) {
            specialsMineInfo.list.push(item);
            specialsMineInfo.totalCoin += item.lucky_coin;
            if (item.opened == 1) {
              specialsMineInfo.coin += item.lucky_coin;
            }
            this.lastTime = this.lastTime < item.remaining_seconds ? item.remaining_seconds : this.lastTime;
          }
        });
        for (let i = specialsMineInfo.list.length; i < 3; i++) {
          specialsMineInfo.list.push({
            gray: true
          });
        }
        this.setSpecialsMineInfo(specialsMineInfo);
        this.countDown();
      } catch (e) {
        this.doSpecialsMine();
      }
    },
    openSpecialsDetail(row) {
      if (!row.gray) {
        this.specialsItem = row;
        this.popSpecialsDetailShow = true;
      }
    }
  }
};