var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "85%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$attrs.show,
      expression: "$attrs.show"
    }],
    staticClass: "pop-main"
  }, [_vm.detail != null ? [_c('div', {
    staticClass: "head"
  }, [_c('m-head', {
    attrs: {
      "src": _vm.detail.group_icon,
      "size": 50
    }
  }), _c('div', {
    staticClass: "head__label"
  }, [_vm._v(_vm._s(_vm.detail.group_name))])], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__img level"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/level/${_vm.getLevelName(_vm.level)}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(_vm._s(_vm.detail.group_level))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-2.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.group_coin, 3, true)))])])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.user_count, 3, true)))])])]), _c('div', {
    staticClass: "info-item",
    on: {
      "click": _vm.onSkip
    }
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-4.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_c('i', [_vm._v(_vm._s(_vm.$lang("Community")))])])])]), _vm.userData.user_group_id < 1 || _vm.userData.user_group_id === _vm.detail.id ? _c('div', {
    staticClass: "btn-join",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onJoin
    }
  }, [_vm._v(_vm._s(_vm.$lang("Join")))]) : _vm._e()] : _vm._e()], 2)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };