var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityPageBoost"
  }, [_c('city-head'), _c('div', {
    staticClass: "pageBoost-body page-body"
  }, [_c('div', {
    staticClass: "pageBoost-main page-main"
  }, [_c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "list__title"
  }, [_vm._v(_vm._s(_vm.$lang("Free daily boosters")))]), _c('div', {
    staticClass: "list-main"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Turbo');
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/boost-list-icon-2.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Recharge Tap Energy")))]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v(_vm._s(_vm.userData.turbo_count) + "/" + _vm._s(_vm.userData.turbo_max) + " available")])])])])]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "list__title"
  }, [_vm._v(_vm._s(_vm.$lang("Boosters")))]), _c('div', {
    staticClass: "list-main"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Multitap');
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/boost-list-icon-3.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Tap Earnings Upgrade")))]), _c('div', {
    staticClass: "item__reward"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData.multi_tap_level), 3, true)) + " "), _c('span', [_vm._v(" · " + _vm._s(_vm.userData.multi_tap_level + 1) + " Lvl")])])])]), _c('div', {
    staticClass: "item",
    on: {
      "click": function ($event) {
        return _vm.onBoo('Battery');
      }
    }
  }, [_c('div', {
    staticClass: "item-icon wow zoomIn",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/boost-list-icon-4.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Energy Cap Upgrade")))]), _c('div', {
    staticClass: "item__reward"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData.power_level), 3, true)) + " "), _c('span', [_vm._v(" · " + _vm._s(_vm.userData.power_level + 1) + " Lvl")])])])])])])])]), _c('pop-energy', {
    attrs: {
      "show": _vm.popEnergyShow,
      "type": _vm.popType
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };