import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const mineVuex = createNamespacedHelpers("mine");
export const mHeadMixin = {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "taskCount", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...mineVuex.mapState(["earnings"]),
    route() {
      return this.$route.path;
    },
  },
  data() {
    return {
      popAwardTipShow: false
    };
  },
  methods: {
    ...mineVuex.mapActions(["getEarningsIncrement"]),
    onSkip(page, id) {
      console.log("page", page, id);
      if (page == "squad") {
        this.$router.push(`${page}?id=${id}`);
        return;
      }
      this.$router.push(`${page}`);
    },
    onAwardTip() {
      this.popAwardTipShow = true
    }
  },
  mounted() {
    // this.getEarningsIncrement()
    //this.loadTaskCount();
  },
};
