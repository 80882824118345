import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export const mMenuMixin = {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "taskCount"]),
    route() {
      return this.$route.path;
    },
  },
  data() {
    return {};
  },
  methods: {
    onSkips(page, id) {
      console.log("pagepagepagepage", page, this.route);
      this.$router.push(`${page}`);
    },
  },
  mounted() {
    //this.loadTaskCount();
  },
};
