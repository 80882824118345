var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pageSquad"
  }, [_c('div', {
    staticClass: "squad-main"
  }, [_vm.detail != null ? [_c('div', {
    staticClass: "head wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('m-head', {
    attrs: {
      "src": _vm.detail.group_icon,
      "size": 50
    }
  }), _c('div', {
    staticClass: "head__label wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_vm._v(_vm._s(_vm.detail.group_name))])], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "info-item wow fadeInLeft",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "info__img level"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-1.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(_vm._s(_vm.detail.group_level))])]), _c('div', {
    staticClass: "info-item wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-2.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.group_coin, 3, true)))])]), _c('div', {
    staticClass: "info-item wow fadeInLeft",
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info__label"
  }, [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.detail.user_count, 3, true)) + " ")])]), _c('div', {
    staticClass: "info-item wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.7s"
    },
    on: {
      "click": _vm.onSkip
    }
  }, [_c('div', {
    staticClass: "info__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/squad-icon-4.png`),
      "alt": ""
    }
  })]), _vm._m(0)])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn invite wow fadeInLeft",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    attrs: {
      "data-wow-duration": "0.9s"
    },
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang("Invite friend")))]), _c('div', {
    staticClass: "btn leave wow fadeInRight",
    style: {
      background: _vm.themeMainColor(_vm.theme).second
    },
    attrs: {
      "data-wow-duration": "0.9s"
    },
    on: {
      "click": _vm.leaveSquadFn
    }
  }, [_vm._v(_vm._s(_vm.$lang("Leave Squad")))])]), _vm.rankList.length > 0 ? [_c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, _vm._l(_vm.rankList, function (item, index) {
    return _c('div', {
      key: `l${index}`,
      staticClass: "item",
      attrs: {
        "data-index": index
      }
    }, [_c('div', {
      staticClass: "item-icon"
    }, [index === 0 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-1.png`),
        "alt": ""
      }
    }) : index === 1 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-2.png`),
        "alt": ""
      }
    }) : index === 2 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-3.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(index + 1))])]), _c('div', {
      staticClass: "item-info"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color,
        "size": "26"
      }
    }), _c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))])], 1), _c('div', {
      staticClass: "item-price"
    }, [_c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.coin, 3, true)) + " ")])])]);
  }), 0)] : _vm._e()] : _vm._e()], 2), _c('pop-prompt', {
    attrs: {
      "show": _vm.popPromptShow,
      "title": _vm.$lang('Are you sure you want to exit?')
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptShow = $event;
      },
      "cancel": _vm.onCancel,
      "confirm": _vm.onConfirm
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "info__label"
  }, [_c('i', [_vm._v("Community")])]);
}];
export { render, staticRenderFns };