const assetsManifest = require(`@assets/js/assetsManifest`);
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "preload",
  props: {},
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    imgUrlArr() {
      if (assetsManifest.assets && assetsManifest.assets.length > 0) {
        return assetsManifest.assets.map(it => {
          return require(`@images/${this.theme}/${it}`);
        });
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      msg: "",
      show: true,
      imgsSum: NaN,
      loadedCount: 0,
      total: 100,
      tip: "",
      tipType: 1
    };
  },
  watch: {
    "imgUrlArr.length": {
      async handler(len) {
        if (len > 0) {
          this.tipType = 1;
          this.imgsSum = this.imgUrlArr.length;
          const RADIO = 1.93;
          this.tip = this.$lang("Get login information...");
          this.loadedCount = RADIO;
          const login = await this.login([null, this.WebApp.initDataUnsafe.start_param || ""]);
          if (login !== 1) {
            this.tipType = 0;
            this.tip = login.message;
            return;
          }
          this.tip = this.$lang("Loading Resources...");
          this.unorderedPreload();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...userVuex.mapActions(["login"]),
    unorderedPreload() {
      // 无序加载，并发下载图片
      this.imgUrlArr.forEach((imgUrl, i) => {
        var oImg = new Image();
        oImg.addEventListener("load", this.imgLoaded);
        oImg.addEventListener("error", this.imgLoaded);
        oImg.src = imgUrl;
      });
    },
    imgLoaded() {
      // 每次图片加载完成执行，无论成功或者失败
      this.loadedCount++;
      if (this.loadedCount >= this.imgsSum) {
        // this.show = false;
        this.$emit("imgAllLoaded");
      }
    }
  }
};