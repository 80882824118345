var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityPageFrens"
  }, [_c('div', {
    staticClass: "pageFrens-main"
  }, [_c('div', {
    staticClass: "head wow fadeInDown",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/friend-head-bg.jpg`)}) center top/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "head-main"
  }, [_c('div', {
    staticClass: "head__title wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Lnvite Friends")))]), _c('div', {
    staticClass: "head__sub wow fadeInUp",
    attrs: {
      "data-wow-duration": "1.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("You and your friend will receive bonuses")))])])]), _c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "intro-item left wow fadeInLeft",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/friends-img-1.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "intro-item-main"
  }, [_c('div', {
    staticClass: "intro-item__reward"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/coin.png`),
      "alt": ""
    }
  }), _c('em', [_vm._v("+" + _vm._s(_vm._f("nFormatter")(_vm.setting.reward.normal, 2)))])])]), _c('div', {
    staticClass: "intro-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("For you and your friend")))])])]), _c('div', {
    staticClass: "intro-item right wow fadeInRight",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/friends-img-2.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "intro-item-main"
  }, [_c('div', {
    staticClass: "intro-item__reward"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/coin.png`),
      "alt": ""
    }
  }), _c('em', [_vm._v("+" + _vm._s(_vm._f("nFormatter")(_vm.setting.reward.premium, 2)))])])]), _c('div', {
    staticClass: "intro-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("For you and your friend if he has Telegram Premium")))])])])]), _vm.firendList && _vm.firendList.length > 0 ? _c('div', {
    staticClass: "overview"
  }, [_c('div', {
    staticClass: "overview__title"
  }, [_vm._v(_vm._s(_vm.userData.extra_invite_count) + " Frens")]), _vm.firendList && _vm.firendList.length > 0 ? [_c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, _vm._l(_vm.firendList, function (item, index) {
    return _c('div', {
      key: `l${index}`,
      staticClass: "item",
      attrs: {
        "data-index": index
      }
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color,
        "size": 30
      }
    }), _c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.coin)) + " ")])], 1);
  }), 0)] : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-send wow fadeInUp",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    attrs: {
      "data-wow-duration": "1s"
    },
    on: {
      "click": _vm.onBtnSend
    }
  }, [_vm._v(_vm._s(_vm.$lang("Send to invite")))])]), _c('pop-share', {
    attrs: {
      "show": _vm.popShareShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popShareShow = $event;
      }
    }
  }), _c('pop-extra-invite', {
    attrs: {
      "show": _vm.popExtraInviteShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popExtraInviteShow = $event;
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };