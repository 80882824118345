import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
export default {
  name: "cityPageDaily",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...dailyViex.mapState(["steps", "tomorrowDistance"])
  },
  data() {
    return {
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: "",
      popSignShow: false,
      todayItem: {}
    };
  },
  // beforeRouteLeave(to, from, next) {
  //   this.todayItem = {};
  //   if (this.lastTimer) {
  //     clearInterval(this.lastTimer);
  //   }
  //   setTimeout(() => {
  //     next();
  //   }, 100);
  // },

  beforeDestroy() {
    this.todayItem = {};
    if (this.lastTimer) {
      clearInterval(this.lastTimer);
    }
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedDaily"]),
    sign_in() {
      // if (!item.completed && item.today) {
      //   this.todayItem = item;
      // }
      this.popSignShow = true;
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
        console.log("lastTime", this.lastTime, this.lastTimeStr);
      }, 1000);
    }
  },
  async mounted() {
    await this.getSteps([1]);
    this.lastTime = this.tomorrowDistance;
    this.countDown();
    const todayItem = this.steps.filter(item => {
      return item.today && !item.completed;
    });
    if (todayItem.length > 0) this.todayItem = todayItem[0];
    console.log("todayItem", this.todayItem);
  }
};