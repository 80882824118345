/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./popCoinHero.vue?vue&type=template&id=df697206&scoped=true"
import script from "./popCoinHero.vue?vue&type=script&lang=js"
export * from "./popCoinHero.vue?vue&type=script&lang=js"
import style0 from "./popCoinHero.vue?vue&type=style&index=0&id=df697206&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df697206",
  null
  
)

export default component.exports