import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityHead"
  }, [_c('div', {
    staticClass: "cityHead-main"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-main"
  }, [_vm.userData ? _c('div', {
    staticClass: "header-avatar",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/avatar-${_vm.userData.avatar_url || 1}.png`)}) center top/120% auto no-repeat`
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/store');
      }
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "menu-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("Memetop")))]), _c('div', {
    staticClass: "menu-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.userData.coin, 2)) + " ")])]), !isNaN(_vm.earnings.hour_earnings) ? _c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "menu-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("Per hour")))]), _c('div', {
    staticClass: "menu-item__value",
    on: {
      "click": _vm.onAwardTip
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/coin.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm._f("nFormatter")(_vm.earnings.hour_earnings, 2))), _c('span', {
    staticClass: "menu-item__info"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/mark.png`),
      "alt": ""
    }
  })])])]) : _vm._e()]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-more",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/rank');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/head-icon-rank.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "btn-more",
    on: {
      "click": function ($event) {
        return _vm.onSkip(_vm.userData.user_group_id <= 0 ? 'join' : 'squad', _vm.userData.user_group_id);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/${this.userData.user_group_id <= 0 ? 'head-icon-squad' : 'head-icon-squad-active'}.png`),
      "alt": ""
    }
  })])])]), _c('pop-award-tip', {
    attrs: {
      "show": _vm.popAwardTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };