var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_vm.$attrs.show ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_vm.getTypeIcon ? _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/${_vm.getTypeIcon}`),
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.getTypeTitle === 'Turbo' && "Recharge Tap Energy" || _vm.getTypeTitle === 'Multitap' && "Tap Earnings Upgrade" || _vm.getTypeTitle === 'Battery Pack' && "Energy Cap Upgrade"))]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm.type === 'Multitap' ? [_vm._v("Amount value per coin "), _c('br'), _c('i', [_vm._v("+1")]), _vm._v(" for each level")] : _vm.type === 'Battery' ? [_vm._v("Increase total coin limit "), _c('br'), _c('i', [_vm._v("+500")]), _vm._v(" for the next level")] : _vm.type === 'Recharge' ? [_vm.userData[_vm.getTypeField] < 3 ? [_vm._v(" Increase coin fil speed "), _c('br'), _c('i', [_vm._v("+1/sec")]), _vm._v(" for each level ")] : [_vm._v(" Current recharge speed: "), _c('br'), _c('i', [_vm._v("+4/sec")])]] : _vm.type === 'Bot' ? [_vm.botList[_vm.userData.bot_level] ? [_vm._v(" Upgrade to " + _vm._s(_vm.userData.bot_level + 1) + " level - Automatic collection will start after 10 minutes of inactivity, with a maximum working duration of " + _vm._s(_vm._f("toHours")(_vm.botList[_vm.userData.bot_level] ? _vm.botList[_vm.userData.bot_level].maxtime : 0)) + " hours. ")] : [_vm._v(" You have reached the max level"), _c('br'), _vm._v(" Automatic collection duration: " + _vm._s(_vm._f("toHours")(_vm.botList[_vm.userData.bot_level - 1].maxtime)) + " hours ")]] : _vm._e()], 2), _c('div', {
    staticClass: "energy-coin"
  }, [['Turbo', 'Energy', 'Multitap', 'Battery'].includes(_vm.type) || _vm.type == 'Bot' && _vm.userData.bot_level < _vm.botList.length || _vm.type == 'Recharge' && _vm.userData[_vm.getTypeField] < 3 ? _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-small-coin.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm.type != 'Turbo' && _vm.type != 'Energy' ? [_vm.type == 'Bot' ? [_vm.botList[_vm.userData.bot_level] ? [_c('span', [_vm._v(_vm._s(_vm.toPre(_vm.botList[_vm.userData.bot_level] ? _vm.botList[_vm.userData.bot_level].price : 0, 0, true)))]), _vm._v("/Lv" + _vm._s(_vm.userData["bot_level"] + 1) + " ")] : _vm._e()] : _vm.type == 'Recharge' && _vm.userData[_vm.getTypeField] == 3 ? void 0 : [_c('span', [_vm._v(_vm._s(_vm.toPre(_vm.getLevelPrice(_vm.userData[_vm.getTypeField]), 3, true)))]), _vm._v("/Lv" + _vm._s(_vm.userData[_vm.getTypeField] + 1) + " ")]] : [_vm._v(" " + _vm._s(_vm.$lang("Free")) + " ")]], 2), _vm.type == 'Turbo' || _vm.type == 'Energy' ? [_vm.type == 'Turbo' ? [_vm.userData.turbo_count > 0 ? _c('div', {
    staticClass: "btn-level",
    class: !_vm.isFlag && 'dis',
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm.isFlag ? [_vm._v(" " + _vm._s(_vm.$lang("Use")) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("In use")) + " ")]], 2) : _c('div', {
    staticClass: "btn-level as"
  }, [_vm._v(_vm._s(_vm.$lang("No times, cannot be used")))])] : _vm._e(), _vm.type == 'Energy' ? [_vm.userData.refill_count > 0 ? _c('div', {
    staticClass: "btn-level",
    class: !_vm.isFlag && 'dis',
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm.isFlag ? [_vm._v(" " + _vm._s(_vm.$lang("Use")) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("In use")) + " ")]], 2) : _c('div', {
    staticClass: "btn-level as"
  }, [_vm._v(_vm._s(_vm.$lang("No times, cannot be used")))])] : _vm._e()] : [['Turbo', 'Energy', 'Multitap', 'Battery'].includes(_vm.type) || _vm.type == 'Bot' && _vm.userData.bot_level < _vm.botList.length || _vm.type == 'Recharge' && _vm.userData[_vm.getTypeField] < 3 ? _c('div', {
    staticClass: "btn-level",
    class: !_vm.isFlag && 'dis',
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm.isFlag ? [_vm._v(" " + _vm._s(_vm.$lang("Level Up")) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("Upgrading")) + " ")]], 2) : _vm._e()]], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };