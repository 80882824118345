var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityPageCity"
  }, [_c('city-head'), _c('div', {
    staticClass: "cityPageCity-body page-body"
  }, [_c('div', {
    staticClass: "cityPageCity-main page-main"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/city-bg.jpg`)}) center top/100% auto no-repeat`
    }
  }), _c('div', {
    staticClass: "city-main"
  }, _vm._l(_vm.cityList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "city-item wow zoomIn",
      class: [`cityItem${index + 1}`, item.lock && 'lock'],
      attrs: {
        "data-wow-duration": "0.5s"
      },
      on: {
        "click": function ($event) {
          return _vm.onSkip(item);
        }
      }
    }, [[!item.lock ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/city-menu-lock.png`),
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/city-menu-premiumStore.png`),
        "alt": ""
      }
    })], _c('div', {
      staticClass: "city-item__label"
    }, [_vm._v(_vm._s(item.label))])], 2);
  }), 0)])]), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };