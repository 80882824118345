import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "store"
  }, [_c('city-head'), _c('div', {
    staticClass: "store-body page-body"
  }, [_c('div', {
    staticClass: "store-main page-main"
  }, [_c('div', {
    staticClass: "store-con"
  }, [_c('div', {
    staticClass: "store__name"
  }, [_vm._v(_vm._s(_vm.$lang("Premium Store")))]), _c('div', {
    staticClass: "store-list"
  }, [_c('div', {
    staticClass: "store-item wow fadeInLeft",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/city-img-1.jpg`)}) center top/100% auto no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/avatar?n=avatar');
      }
    }
  }), _c('div', {
    staticClass: "store-item wow fadeInRight",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/city-img-2.jpg`)}) center top/100% auto no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/avatar?n=background');
      }
    }
  })])])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };