import "core-js/modules/es.array.push.js";
import loadConfig from "@Web3WalletConfig/config";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "avatar",
  computed: {
    ...userVuex.mapState(["userData"]),
    ...walletVuex.mapState(["theme"]),
    loadConfig() {
      return loadConfig;
    }
  },
  data() {
    return {
      selectTab: "avatar",
      selectAvatar: 1,
      isUseLoading: false,
      selectBackground: "basic"
    };
  },
  watch: {
    "userData.avatar_url": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.selectAvatar = newVal || 1;
      },
      deep: true,
      immediate: true
    },
    "userData.skin": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectBackground = newVal;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...userVuex.mapActions(["updateResultAvatar"]),
    onSelect(index) {
      this.selectAvatar = index;
    },
    onBgSelect(skin) {
      this.selectBackground = skin;
    },
    async onUse() {
      this.isUseLoading = true;
      const res = await this.updateResultAvatar({
        avatar: this.selectAvatar,
        skin: this.selectBackground
      });
      if (!res) {
        this.isUseLoading = false;
        return;
      }
      this.isUseLoading = false;
      this.$nextTick(() => {
        this.$router.push("/");
      });
    }
  },
  mounted() {
    this.setWowAnim();
    const n = this.$route.query.n;
    console.log("aaa", n);
    if (!n) {
      this.$router.go(-1);
      return;
    }
    this.selectTab = n;
  }
};