var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "myHead",
    style: {
      backgroundColor: !_vm.src && _vm.bgColor,
      '--width': _vm.remTran(_vm.size)
    }
  }, [_vm.src ? [_c('img', {
    attrs: {
      "src": _vm.src,
      "alt": ""
    }
  })] : [_c('span', {
    style: {
      fontSize: _vm.remTran(_vm.fontSize)
    }
  }, [_vm._v(_vm._s(_vm._f("getUserShortName")(_vm.name)))])]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };