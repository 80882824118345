var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityPageMine"
  }, [_c('city-head'), _c('div', {
    staticClass: "pageMine-body page-body"
  }, [_c('div', {
    staticClass: "pageMine-main page-main",
    attrs: {
      "id": "mine"
    }
  }, [_c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.categoryList, function (item, index) {
    return _c('div', {
      staticClass: "tab-item",
      class: _vm.selectTab == item.id ? 'checked' : '',
      on: {
        "click": function ($event) {
          !_vm.isMobile && _vm.click_tab(item.id);
        },
        "touchstart": function ($event) {
          _vm.isMobile && _vm.click_tab(item.id);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0), [_c('div', {
    staticClass: "list"
  }, [_vm.isLoading ? [_c('div', {
    staticClass: "sLoading"
  }, _vm._l(3, function (_) {
    return _c('span');
  }), 0)] : [_c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.taskList[_vm.selectTab] ? _vm._l(_vm.taskList[_vm.selectTab], function (item, index) {
    return _c('div', {
      key: `l${index}`,
      staticClass: "item",
      class: item.pre_task_id != 'null' && !item.upgradable ? 'lock' : '',
      attrs: {
        "data-index": index
      },
      on: {
        "click": function ($event) {
          !_vm.isMobile && _vm.onBoo(item);
        },
        "touchstart": function ($event) {
          _vm.isMobile && _vm.onBoo(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-main"
    }, [_c('div', {
      staticClass: "item-img"
    }, [_c('div', {
      staticClass: "item-img-main"
    }, [_c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "item-lock"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/lock.png`),
        "alt": ""
      }
    })])])]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__title"
    }, [_vm._v(_vm._s(item.short_name))]), _c('div', {
      staticClass: "item-ele"
    }, [_c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), item.current_level == 0 ? _c('span', [_vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.initial_earnings, 2)) + " ")]) : _c('span', [item.current_earnings > 0 ? [_vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.current_earnings, 2)) + " ")] : [_vm._v("--")]], 2)])])]), _c('div', {
      staticClass: "item-price"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(item.upgrade_cost, 2)) + " ")]), item.current_level > 0 ? _c('div', {
      staticClass: "item-level"
    }, [_c('span', [_vm._v(_vm._s(item.current_level))])]) : _vm._e()])]);
  }) : _vm._e()], 2)]], 2)]], 2)]), _c('pop-mine-energy', {
    attrs: {
      "show": _vm.popEnergyShow,
      "type": _vm.selectCategory.icon_type,
      "taskInfo": _vm.taskInfo
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  }), _c('pop-tip-energy', {
    attrs: {
      "show": _vm.popEngrayTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEngrayTipShow = $event;
      }
    }
  }), _c('pop-award-tip', {
    attrs: {
      "show": _vm.popAwardTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardTipShow = $event;
      }
    }
  }), _c('pop-award', {
    attrs: {
      "show": _vm.popAwardShow,
      "award": _vm.awardCoin
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardShow = $event;
      }
    }
  }), _c('pop-special-detail', {
    attrs: {
      "show": _vm.popSpecialsDetailShow,
      "row": _vm.specialsItem
    },
    on: {
      "update:show": function ($event) {
        _vm.popSpecialsDetailShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };